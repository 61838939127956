<template>
    <div>
        <v-overlay
            :value="loading"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-form lazy-validation ref="agentsCommissionsForm">
            <v-data-table
                :headers="headers"
                :items="AgentCommissions"
                :items-per-page="-1"
                class="mt-0 appic-table-light specification-table"
                dense
                hide-default-footer
                item-key="Commission.supplier_id"
            >
                <template v-slot:item.Commission.rate_per_cbm="{ item }">
                    <v-text-field
                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                        :value="item.Commission.rate_per_cbm"
                        class="custom pa-1 bg-lightgrey width-60-px"
                        dense
                        hide-details="auto"
                        @change="updateCommissionItemRowHandle( item.index, 'Commission.rate_per_cbm', $event)"
                    />
                </template>
                <template v-slot:item.Commission.app_minimum_margin="{ item }">
                    <v-text-field
                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                        :value="item.Commission.app_minimum_margin"
                        class="custom pa-1 bg-lightgrey width-60-px float-right"
                        dense
                        hide-details="auto"
                        @change="updateCommissionItemRowHandle( item.index, 'Commission.app_minimum_margin', $event)"
                    />
                </template>
            </v-data-table>
        </v-form>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {api} from "Api";

export default {
    name: "AgentCommissions",
    props: ['supplierId', 'updateMode'],
    data() {
        return {
            loading: false
        }
    },
    computed: {
        statePrefix(){
            if(this.updateMode){
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapMultiRowFieldsPrefixed('supplier',{
            AgentCommissions: 'AgentCommissions',
        },'statePrefix'),
        ...mapFieldsPrefixed('supplier', {
            Supplier__id: 'Supplier.id',
            Supplier__has_commission: 'Supplier.has_commission',
            Supplier__suppliers_represented: 'Supplier.suppliers_represented'
        },'statePrefix'),
        ...mapGetters('supplier', {
            allSuppliers: 'allSuppliers',
            findSupplierById: 'findSupplierById'
        }),
        ...mapGetters([
            'validationRules'
        ]),
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.supplier'),
                    value: 'Commission.supplier_name',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 2,
                    text: this.$t('message.otsName'),
                    value: 'Commission.supplier_otsname',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 3,
                    text: this.$t('message.ratePerCbm'),
                    value: 'Commission.rate_per_cbm',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 4,
                    text: this.$t('message.appMinimumMargin'),
                    value: 'Commission.app_minimum_margin',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: true,
                    searchable: false
                },
            ]
        }
    },
    methods: {
        ...mapActions('supplier', {
            resetCommissions: 'resetCommissions',
            setAgentCommissions: 'setAgentCommissions',
        }),
        getAgentCommissions() {
            if(this.supplierId == null) {
                if(this.Supplier__suppliers_represented.length > 0) {
                    //build array of agent commissions
                    let i = 0
                    let agents = []
                    this.Supplier__suppliers_represented.forEach(supplierId => {
                        const supplier = this.findSupplierById(supplierId)
                        const obj = {
                            index: i,
                            Commission: {
                                id: null,
                                supplier_id: supplier.Supplier.id,
                                supplier_name: supplier.Supplier.title,
                                supplier_otsname: supplier.Supplier.otsname,
                                rate_per_cbm: 0,
                                app_minimum_margin: 0
                            }
                        }
                        agents.push(obj)
                        i++
                    })
                    const payload = {
                        prefix: this.statePrefix,
                        data: agents
                    }
                    this.setAgentCommissions(payload)
                }
            } else {
                this.loading = true;
                api
                    .get('/suppliers/' + this.supplierId + '/agent-commissions')
                    .then((response) => {
                        if(response.data.status === 'success') {
                            const payload = {
                                prefix: this.statePrefix,
                                data: response.data.data
                            }
                            this.setAgentCommissions(payload);
                        } else {
                            this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading = false;
                    })
            }
        },
        updateCommissionItemRowHandle(idx, field, value) {
            this.$store.commit('supplier/updateAgentCommissionItems', {
                index: idx,
                field: field,
                prefix: this.statePrefix,
                value: value
            })
        }
    },
    watch: {
        Supplier__has_commission(val) {
            if(val === 1) {
                this.getAgentCommissions()
            } else {
                this.resetCommissions(this.statePrefix)
            }
        },
        Supplier__suppliers_represented(val) {
            this.getAgentCommissions() //reload
        }
    },
    created() {
        this.resetCommissions(this.statePrefix)
    },
    mounted(){
        this.$nextTick(() => {
            if(this.Supplier__has_commission === 1 && this.AgentCommissions.length === 0) {
                this.getAgentCommissions() //reload
            }
        })
    }
}
</script>

<style scoped>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.v-data-table::v-deep tbody td.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table::v-deep td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table::v-deep v-data-table-header th {
    white-space: nowrap;
}
.width-60-px {
    width: 60px !important;
}
</style>